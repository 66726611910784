<template>
<div>
<b-card>
  <app-timeline>
    <app-timeline-item
        v-for="item in vehicule.historiques"
        :key="item.id"
        :title="item.log"
        :subtitle="item.user"
        :time="item.date"
        variant="success"
    />

  
  </app-timeline>
</b-card>
</div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: "VehiculeActivites",
  props : {
    vehicule : {
      type : Object,
      required : true
    }
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
}
</script>

<style scoped>

</style>