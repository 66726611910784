<template>
  <div>
    <b-row>

      <b-col md="6" xl="8" v-if="vehicule">
        <b-card title="Canaux et prix d'annonce">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="Votre prix d'annonce TTC">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <b-form-checkbox
                          v-model="canaux_prix_annonce.is_canal_vente_particulier_actif"
                      />
                    </b-input-group-prepend>
                    <cleave
                        id="number"
                        :disabled="!canaux_prix_annonce.is_canal_vente_particulier_actif"
                        v-model="canaux_prix_annonce.prix_vente_particulier_ttc"
                        class="form-control"
                        :raw="true"
                        :options="{ numeral: true }"
                        placeholder="10,000"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Prix marchands TTC">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <b-form-checkbox v-model="canaux_prix_annonce.is_canal_vente_marchand_actif">

                      </b-form-checkbox>
                    </b-input-group-prepend>
                    <cleave
                        id="number"
                        :disabled="!canaux_prix_annonce.is_canal_vente_marchand_actif"
                        v-model="canaux_prix_annonce.prix_vente_marchand_ttc"
                        class="form-control"
                        :raw="true"
                        :options="{ numeral: true }"
                        placeholder="10,000"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>


              <!-- submit and reset -->
              <b-col>
                <b-button
                    @click="submit"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Enregistrer
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="6" xl="4">
        <vehicule-la-cote :vehicule-id="vehicule.id"></vehicule-la-cote>

      </b-col>
    </b-row>


  </div>
</template>

<script>
import VehiculeLaCote from "@/components/Vehicule/VehiculeLaCote";

export default {
  name: "CanauxPrixAnnonceFrom",
  components: {VehiculeLaCote},
  props: {
    vehicule: {
      required: true,
      default: {}
    }
  },
  data() {
    return {
      canaux_prix_annonce: {
        prix_vente_particulier_ttc: 0,
        prix_vente_marchand_ttc: 0,
        is_canal_vente_particulier_actif: true,
        is_canal_vente_marchand_actif: false,
      }
    }
  },
  created() {
    if (this.vehicule) {
      this.canaux_prix_annonce = {...this.vehicule.canaux_prix_annonce};
    }
  },
  methods: {
    submit() {
      let _this = this;
      this.$store.dispatch('gestionStockModule/updateCanauxPrixAnnonce', {
        vehicule_id: this.vehicule.id,
        ...this.canaux_prix_annonce
      }).then(function(response){
        console.log(response);  
        _this.$emit('update-vehicule', response.vehicule);
      });
    }
  }
}
</script>

<style scoped>

</style>