<template>

  <b-card no-body v-if="vehiculeId && result ">

    <b-card-header v-if="result">
      <b-card-title>
        Offre LaCote.ma
      </b-card-title>
      MAJ le {{result.date_update}}
    </b-card-header>
 
    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-1" v-if="!loading && result.statut == 1">
        <div class="mr-2">
          <b-card-text class="mb-50">
            Cote standard
          </b-card-text>
          <h4 class="font-weight-bolder">

            <span class="text-primary">{{result.cote_standard}} MAD</span>
          </h4>
        </div>
        <div>
          <b-card-text class="mb-50">
            Cote personnalisée
          </b-card-text>
          <h4 class="font-weight-bolder">

            <span>{{result.cote_personalisee}} MAD</span>
          </h4>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-1" v-if="!loading && result.statut == 0">
        <div class="mr-2">
          <b-card-text class="mb-50">
            {{result.error_message}}
          </b-card-text>
         
        </div>
        
      </div>

      <div class="d-flex align-items-center mb-2" v-if="loading" >
 
        <b-spinner class="m-auto" />
      </div>
      
      <div class="d-flex justify-content-center mb-2">
        <div class="">
          <b-button
              size="sm"
              @click="force_update"
              variant="primary"
          >
            Mettre à jour
          </b-button>
        </div>

      </div>
      
    </b-card-body>
  </b-card>
</template>

<script>
 

export default {
  name: "VehiculeLaCote",
  props : {
    vehiculeId : {
      default : false,
    }
  },
  data(){
    return {
      result : false,
      loading : false
    }
  },
  methods : {
    force_update(){
      let _this = this;
      _this.loading = true;
      this.$store.dispatch('laCoteModule/fetch',{
        vehicule_id : this.vehiculeId, 
        force_update : 1,
        
      }).then(function(response){
        _this.result = response.data;
        _this.loading = false;
      })
    }
  },
  mounted() {
    let _this = this;
    _this.loading = true;
    this.$store.dispatch('laCoteModule/fetch',{
      vehicule_id : this.vehiculeId
    }).then(function(response){
      _this.result = response.data;
      _this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>