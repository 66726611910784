 
  <template>
    <b-tabs>
      <b-tab active title="Informations véhicule" >
        <div v-if="vehicule">
          <vehicule-form type-ajout="stock" :disabled-fields="disabledFields"  @created="vehiculeUpdatedHandler" :initial-vehicule="vehicule"/>
        </div>
      </b-tab>
      <b-tab lazy title="Canaux et prix d'annonce" >
        <div v-if="vehicule">
          <canaux-prix-annonce-from @update-vehicule="vehiculeUpdatedHandler" :vehicule="vehicule" />
        </div>
      </b-tab>
      <b-tab  title="Photos" >
        <div v-if="vehicule">
         <photos-form :vehicule="vehicule" />
         <photos-renew-form title="360 extérieur" :vehicule="vehicule" />
   
        </div>
      </b-tab>
      <b-tab title="Activité" >
        <div v-if="vehicule">
          <vehicule-activites :vehicule="vehicule" />
        </div>
      </b-tab>


    </b-tabs>
  </template>

 

<script>

import VehiculeForm from "@/components/VehiculeForm";
import CanauxPrixAnnonceFrom from "@/components/Vehicule/VehiculesCanauxPrixAnnonceFrom";
import PhotosForm from "@/components/Vehicule/VehiculePhotosForm";
import VehiculeActivites from "@/components/Vehicule/VehiculeActivites";
import PhotosRenewForm from "@/components/Vehicule/VehiculePhotosRenewForm.vue";
 

export default {
  components: {
    VehiculeActivites,
    PhotosForm,
    CanauxPrixAnnonceFrom,
    VehiculeForm,
    PhotosRenewForm
 

  },
  name: "VehiculeEditPage",
  data(){
    return {
      vehicule : false,
      disabledFields: [],
    }
    
  },
  mounted() {

    this.disabledFields = ['concession', 'site'];

    if(this.$can('voir-stock-global', 'stock') || this.$can('voir-stock-concession', 'stock')){
      this.disabledFields = [];
    }
    if((this.$can('voir-stock-site', 'stock'))){
      this.disabledFields = ['concession'];
    }
    
    
    
    
    let _this = this;
    this.$store.dispatch('gestionStockModule/fetchVehicule', {
      vehicule_id : this.$route.params.id
    }).then(function (response){
      _this.vehicule = response.data;
    })
  },
  methods: {
    vehiculeUpdatedHandler(vehicule){
      this.vehicule = vehicule;
    }
  },
}
</script>

<style scoped>

</style>