<template>
  <div>
    <b-card :title="title" >
      <div class="clearfix" v-if="uploadedImages.length">
        <div class="photos">
          <div v-for="image in uploadedImages" :key="image.id" class="photo mb-1 mr-1">
            <div>
              <b-img  thumbnail  class="mb-1 mb-sm-0"  :src="image.path + '?w=230&h=230'"/>
            </div>
            <b-button @click="delete_image(image.id)" size="sm" class="mt-1">Supprimer</b-button>
    
          </div>
        </div>
      </div>
      <b-card title="Ajouter Photos">
        <div class="clearfix">
          <div class="photos">
            <div v-for="(image, key) in base64Images" :key="key" class="photo mb-1 mr-1">
              <div>
                <b-img width="200"  thumbnail  class="mb-1 mb-sm-0"  :src="image.image"/>
              </div>
              
              <b-button size="sm" class="mt-1">Supprimer</b-button>
            </div>
          </div>
        </div>
        <div>
          <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              multiple
              accept="image/*"
              @change="previewFiles"
          />
          <b-button :disabled="!images.length" @click="uploadFiles" variant="primary" class="mt-1">
            Uploader
          </b-button>
      
        </div>
      </b-card>
    </b-card>
    
  </div>

  
</template>

<script>
import axios from "@/libs/axios";

export default {
  name: "VehiculePhotosRenewForm",
  props : {
    vehicule : {
      required : true
    },
    title : {
      default : "Photos véhicules"
    }
  },
  data(){
    return {
      uploadedImages : [],
      images : [],
      previews : [],
      base64Images : []
    }
  },
  methods : {
    uploadFiles(){
      let _this = this;
      console.log(this.base64Images);

      
      this.base64Images.forEach(function(item, index){
        console.log(item, index);
        axios.post('/api/vehicules/images-upload-360', {
          image : item.image,
          vehicule_id : _this.vehicule.id,
          order : item.order
        }).then(function (response){
          _this.uploadedImages = response.data.data.images;
          _this.images = [];
          _this.previews = [];
          _this.base64Images = _this.base64Images.filter(function(a, b){
            return item.index != a.index;
          })
          //_this.base64Images = [];
        });
      });
      
      return false;
      axios.post('/api/vehicules/images-upload', {
        images : this.base64Images,
        vehicule_id : this.vehicule.id
      }).then(function (response){
        _this.uploadedImages = response.data.data.images;
        _this.images = [];
        _this.previews = [];
        _this.base64Images = [];
        
      });
    },
    delete_image(vehicule_image_id){
      let _this = this;
      axios.post('/api/vehicules/remove-image', {
        vehicule_image_id : vehicule_image_id
      }).then(function(response){
        _this.uploadedImages = _this.uploadedImages.filter(function(item){
           return item.id != vehicule_image_id;
        })
      });
    },
    previewFiles(e) {
      this.previews = [];
    
      let vm = this;
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        console.log(selectedFiles[i]);
        this.images.push(selectedFiles[i]);
      }
      this.base64Images = [];
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
 
          this.base64Images.push({
            image : reader.result,
            index : i,
            order : i+1
          })
 
        };
        reader.readAsDataURL(this.images[i]);
      }
    }

  },
  created() {
    this.uploadedImages = [...this.vehicule.images360]
  }
}
</script>

<style scoped>
  .photo{
    float: left;

 
  }
  .photo > div{
    
  }
  .photo img{
    width: 230px;
    height: 230px;
  }
</style>